<template>
  <v-container v-if="hasPermission" fluid>
    <v-row justify="center">
      <v-col cols="12">
        <mex-heading content="Yearly Cycle Group Statistics" />
      </v-col>
    </v-row>
    <v-row justify="center" class="mb-3">
      <v-col cols="4">
        <v-autocomplete
          v-model="selectedOrganization"
          :items="organizationNames"
          color="primaryAccent"
          dense
          hide-details
          label="Organization"
          outlined
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.text }}</v-list-item-title>
              <v-list-item-subtitle class="ma-2">{{ data.item.description }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          v-model="selectedCycleGroupTemplate"
          :items="cycleGroupTemplates"
          color="primaryAccent"
          dense
          hide-details
          label="Cycle Group Template"
          outlined
        />
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          v-model="selectedYear"
          :items="years"
          color="primaryAccent"
          dense
          hide-details
          label="Year"
          outlined
        />
      </v-col>
    </v-row>
    <mex-sperm-spinner v-if="statisticLoading && selectedCycleGroupTemplate && selectedYear && selectedOrganization" spinnerText="Loading overview" />
    <mex-data-table
      v-else-if="selectedCycleGroupTemplate && selectedYear && selectedOrganization"
      :headers="cycleGroupClinicOverviewHeaders"
      :data="cycleGroupClinicOverview"
      dense
      table-class="foreground"
      :items-per-page="getTablePagination.defaultRowsPerPage"
      :footer-props="{
                    'items-per-page-options': getTablePagination.rowsPerPage,
      }"
    >
      <template v-slot:item.clinicName="{ item }">
        {{ item.clinicName }}
      </template>
      <template v-slot:item.sum="{ item }">
        {{ item.sum }}
      </template>
      <template v-slot:item.goToStatistic="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              x-small
              class="white--text"
              v-bind="attrs"
              v-on="on"
              @click="() => goToCycleTypeStatistics(item.clinicID)"
            >
              <v-icon>mdi-chart-line</v-icon>
            </v-btn>
          </template>
          Go to Clinic's Cycle-Statistics
        </v-tooltip>
      </template>
    </mex-data-table>
  </v-container>
</template>

  <script>
  import { mapGetters } from 'vuex';
  import LicenseServerChart from '../../components/LicSrvComponents/LicenseServerChart.vue';
  import requiredPermissions from '../../requiredPermissions';
  import MexBtn from "../../components/MedITEX_Vue_Components/MexComponents/MexButton.vue";
  import CycleGroupTemplatesService from "../../services/cycleGroupTemplates.service";
  import MexDataTable from "../../components/MedITEX_Vue_Components/MexComponents/MexDataTable.vue";
  import MexSpermSpinner from "../../components/MedITEX_Vue_Components/MexComponents/MexSpermSpinner.vue";
  import MexHeading from "../../components/MedITEX_Vue_Components/MexComponents/MexHeading.vue";
  import OrganizationsService from "../../services/organizations.service";
  import { assignSeveralProperties } from "../../functions/assignPropertyIfExists";
  import getTablePagination from "../../config/tablePagination.config";

  export default {
    name: 'ClinicCycleTypesStatistics',
    components: { MexHeading, MexSpermSpinner, MexDataTable, MexBtn, LicenseServerChart },
    computed: {
      getTablePagination() {
        return getTablePagination
      },
      ...mapGetters('sysAuth', ['getUserPermissions']),
      getSelectedClinic() {
        return this.selectedClinic;
      },
    },
    data() {
      return {
        hasPermission: false,
        // visualization
        statisticLoading: false,
        // selection data
        selectedCycleGroupTemplate: null,
        selectedYear: null,
        cycleGroupTemplates: [],
        years: [],
        cycleGroupClinicOverview: [],
        cycleGroupClinicOverviewHeaders: [
          { text: 'Clinic Name', value: 'clinicName', sortable: true },
          { text: 'Sum', value: 'sum', sortable: true, align: 'center' },
          { text: '', value: 'goToStatistic', sortable: false, align: 'center' }
        ],
        selectedOrganization: null,
        organizationNames: []
      };
    },
    watch: {
      selectedCycleGroupTemplate: {
        handler() {
          if (this.allConditionsSelected()) {
            this.fetchCycleGroupClinicOverview();
          }
          this.$store.commit('selectedProperties/setCycleGroupClinicCycleGroupTemplate', this.selectedCycleGroupTemplate);
        }
      },
      selectedYear: {
        handler() {
          if (this.allConditionsSelected()) {
            this.fetchCycleGroupClinicOverview();
          }
          this.$store.commit('selectedProperties/setCycleGroupClinicYear', this.selectedYear);
        },
      },
      selectedOrganization: {
        handler() {
          if (this.allConditionsSelected()) {
            this.fetchCycleGroupClinicOverview();
          }
          this.$store.commit('selectedProperties/setCycleGroupClinicOrganization', this.selectedOrganization);
        }
      }
    },
    created() {
      this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.cycleTypesStatistics, this.$store)
        .then((hasPermission) => {
          if (hasPermission) {
            this.hasPermission = true;
            let foundProperties = false;
            [this.$route.params, this.$route.query].forEach((route) => {
              if (route && Object.keys(route).length) {
                foundProperties = true;
                if (route.selectedOrganization) {
                  this.selectedOrganization = route.selectedOrganization;
                  this.$store.commit('selectedProperties/setCycleGroupClinicOrganization', this.selectedOrganization);
                }
                if (route.selectedCycleGroupTemplate) {
                  this.selectedCycleGroupTemplate = route.selectedCycleGroupTemplate;
                  this.$store.commit('selectedProperties/setCycleGroupClinicCycleGroupTemplate', this.selectedCycleGroupTemplate);
                }
                if (route.selectedYear) {
                  this.selectedYear = route.selectedYear;
                  this.$store.commit('selectedProperties/setCycleGroupClinicYear', this.selectedYear);
                }
              }
            });
            if (!foundProperties) {
              ["Organization", "CycleGroupTemplate", "Year"].forEach((property) => {
                const storeValue = this.$store.getters[`selectedProperties/cycleGroupClinic${property}`];
                this[`selected${property}`] = storeValue ? JSON.parse(storeValue) : null;
              });
            }
            this.fetchCycleGroupTemplateNames();
            this.calculateYearsSince2017();
            this.fetchOrganizationNames();
          } else {
            this.$router.push({ name: "NotFound" });
          }
        })
        .catch(() => {
          this.$router.push({ name: "NotFound" });
        })
    },
    methods: {
      goToCycleTypeStatistics(clinicID) {
        this.$router.push({
          name: "ClinicCycleTypesStatistics",
          params: {
            organizationID: this.selectedOrganization,
            clinicID: clinicID,
            startDate: `${this.selectedYear}-01-01`,
            endDate: `${this.selectedYear}-12-31`,
          }
        });
      },
      allConditionsSelected() {
        return this.selectedCycleGroupTemplate && this.selectedYear && this.selectedOrganization;
      },
      fetchOrganizationNames() {
        if (this.organizationNames.length === 0) {
          OrganizationsService.getOrganizationNames()
            .then((organizationResponse) => {
              organizationResponse.data.forEach((orga) => {
                this.organizationNames.push({
                  value: orga.OrganizationID,
                  text: orga.name,
                });
              });
            })
            .catch((err) => {
              this.$toast.error(err);
            });
        }
      },
      fetchCycleGroupClinicOverview() {
        this.statisticLoading = true;
        CycleGroupTemplatesService.getCycleGroupClinicOverview(this.selectedOrganization, this.selectedCycleGroupTemplate, this.selectedYear)
          .then((response) => {
            this.cycleGroupClinicOverview = Object.keys(response.data.cycleGroupClinicOverview).map(key => ({
              clinicID: Number(key),
              clinicName: response.data.cycleGroupClinicOverview[Number(key)].clinicName,
              sum: response.data.cycleGroupClinicOverview[Number(key)].sum
            }));
            this.statisticLoading = false;
          })
          .catch((err) => {
            this.$toast.error(`Problem while retrieving Cycle Group Clinic Overview: ${err.message}`);
            this.statisticLoading = false;
          });
      },
      fetchCycleGroupTemplateNames() {
        CycleGroupTemplatesService.getCycleGroupTemplatesNames()
          .then((response) => {
            this.cycleGroupTemplates = response.data.cycleGroupTemplateNames.map(template => template.name);
          })
          .catch((err) => {
            this.$toast.error(`Problem while retrieving Cycle Group Template Names: ${err.message}`);
          });
      },
      calculateYearsSince2017() {
        const currentYear = new Date().getFullYear();
        this.years = Array.from({ length: currentYear - 2017 + 1 }, (_, i) => currentYear - i);
      }
    },
};
</script>

<style>
.ct-grids line {
  stroke: whitesmoke !important;
}

.ct-labels span {
  color: steelblue;
}
</style>
