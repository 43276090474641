import httpClient from '../httpClient/httpClient';

const CycleGroupTemplatesService = {
  getCycleGroupTemplatesNames() {
    return httpClient.get('api/get-cycle-group-templates-names');
  },

  createCycleGroupTemplate(cycleGroupTemplate) {
    return httpClient.post('api/create-cycle-group-templates', {
      cycleGroupTemplate,
    });
  },

  getCycleGroupTemplates() {
    return httpClient.get('api/get-cycle-group-templates');
  },

  getCycleTypesOfTemplate(templateName) {
    return httpClient.get('api/get-cycle-types-of-template', {
      params: {
        templateName
      }
    });
  },

  getCycleGroupTemplatesOfLocationsRegisterType(locationID) {
    return httpClient.get('api/get-cycle-group-templates-of-locations-register-type', {
      params: {
        locationID
      }
    })
  },

  getCycleGroupTemplate(cycleGroupTemplateName) {
    return httpClient.get('api/get-cycle-group-template', {
      params: {
        cycleGroupTemplateName
      }
    })
  },

  deleteCycleGroupTemplate(cycleGroupTemplateID) {
    return httpClient.post('api/delete-cycle-group-template', {
      cycleGroupTemplateID
    });
  },

  getCycleGroupClinicOverview(organizationID, cycleGroupTemplateName, year) {
    return httpClient.get('api/get-cycle-group-clinic-overview', {
      params: {
        organizationID,
        cycleGroupTemplateName,
        year
      }
    });
  }
};


export default CycleGroupTemplatesService;
